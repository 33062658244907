import React from "react";
// import Slider from "react-slick";
// import { HelmetDatoCms } from "gatsby-source-datocms";
import Img from "gatsby-image";
import {Link, graphql } from "gatsby"
import Layout from "../components/layout";
import ResponsiveEmbed from 'react-responsive-embed';

import Header from "../components/Header";
import Footer from "../components/footer.js";

export default ({ data }) => (
    <Layout>
        <Header  content_color="black"/>
        <div className="body">
            <div className="w-layout-grid main-grid partnership-grid">
                <h1
                    id="w-node-bb9af9930568-d30b0379"
                    className="headline partnership-header"
                >
                    {data.datoCmsCollaboration.title}
                </h1>
                <div id="w-node-17444d1f1bed-d30b0379">
                    <Img
                        fluid={data.datoCmsCollaboration.featuredImage.fluid}
                        alt=""
                    />
                </div>
            </div>
        </div>
        <div className="main-grid story-grid">
            
            {typeof data.datoCmsCollaboration.caseStudy !== undefined && (
                <>
                    {data.datoCmsCollaboration.caseStudy.map(block => (

                        <div className={`block ${block.model.apiKey}`} key={block.id}>

                            {block.model.apiKey === "media_one_column" && (
                                <img src={block.image.url} alt="" />
                            )}
                             {block.model.apiKey === "text_block" && (
                                <div className="body-copy-medium" dangerouslySetInnerHTML={{ __html: block.text }}></div>
                            )}
                            {block.model.apiKey === "media_two_column" && (
                                <>
                                    {block.imageRowBlock.map((img, i) => (
                                        <Img fluid={img.fluid} alt="" key={`image-${i}`} />
                                    ))}
                                </>
                            )}
                            {block.model.apiKey === 'video_embed' && (

                                <div className={('content-video')}>
                                    <div className={('content-video__wrapper')}>
                                        {block.url.provider === 'youtube' ? (
                                        <ResponsiveEmbed
                                            src={`//www.youtube.com/embed/${
                                            block.url.providerUid
                                            }`}
                                            ratio={`${block.url.width}:${block.url.height}`}
                                            allowFullScreen
                                        />
                                        ) : (
                                        <ResponsiveEmbed
                                            src={`//player.vimeo.com/video/${
                                            block.url.providerUid
                                            }?title=0&byline=0&portrait=0`}
                                            ratio={`${block.url.width}:${block.url.height}`}
                                            allowFullScreen
                                        />
                                        )}
                                    </div>
                                </div>

                            )}
                        </div>

                     ))}
                </>
            )}
        </div>
        <div className="contact-section">
            <div className="w-layout-grid main-grid">
                
                <h1
                    id="w-node-fce92c3910b2-2c3910b0"
                    className="headline-lyon white"
                ><Link to="/contact">
                    Interested in working with us? Get in touch here 
                    <img
                    src={require("../images/big-arrow.png")}
                    id="w-node-fce92c3910b4-2c3910b0"
                    alt=""
                />
                    </Link>
                </h1>
                
                <h1
                    id="w-node-fce92c3910b5-2c3910b0"
                    className="headline white"
                >
                    Contact
                </h1>
            </div>
        </div>
        <Footer />
    </Layout>
);

export const query = graphql`
query singleCollaborationQuery($slug: String!) {
  datoCmsCollaboration(slug: {eq: $slug}) {
    id
    title
    slug
    featuredImage {
      fluid {
        aspectRatio
        src
        srcSet
        sizes
        ...GatsbyDatoCmsFluid
      }
    }
    caseStudy {
      ... on DatoCmsMediaOneColumn {
        model {
          apiKey
        }
        image {
          url
        }
      }
      ... on DatoCmsTextBlock {
        model {
          apiKey
        }
        text
      }
      ... on DatoCmsMediaTwoColumn {
        model {
          apiKey
        }
        imageRowBlock {
          fluid {
            src
            ...GatsbyDatoCmsFluid
          }
        }
      }
      ... on DatoCmsVideoEmbed {
        model {
          apiKey
        }
        url {
          url
          title
          provider
          providerUid
          thumbnailUrl
          width
          height
        }
      }
    }
  }
}
`;
